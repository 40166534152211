/* eslint-disable  */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { currentchannel } from 'feactures/channel/channelSlice';
import ConnectionDialog from './ConnectionDialog';
import { userinfo } from 'feactures/user/userSlice';
import NewButtongroup from '../group/NewButtongroup';

const PremiumStatusDialog = () => {
  const [open, setOpen] = useState(true);
  const userInfo = useSelector(userinfo);
  const location = useLocation();
  const navigate = useNavigate();
  const location_dict = {
    '/app/categoryboard': {
      title: 'カテゴリ(TOP100)レポート',
      page: 'categoryTop100'
    },
    '/app/ownproductboard': { title: '間接流通レポート', page: 'ownProduct' },
    '/app/otherproductboard': {
      title: '商品分析',
      page: 'otherProduct'
    },
    '/app/oldotherproductboard': {
      title: '競合商品',
      page: 'otherProduct'
    },
    '/app/category1/sales': { title: 'カテゴリNo.1商品', page: 'categoryNo1' },
    '/app/newcategoryboard': { title: 'カテゴリ分析', page: 'category' },
    '/app/brandanalysis': { title: '店舗/ブランド分析', page: 'brandAnalysis' },
    '/app/brandcomparison': {
      title: '店舗/ブランド比較',
      page: 'brandComparison'
    },
    '/app/productmeasures': { title: '商品施策', page: 'productMeasures' }
  };

  let pageTitle = '';
  let page = '';
  pageTitle = location_dict[location.pathname].title;
  page = location_dict[location.pathname].page;

  const channel = useSelector(currentchannel);

  let displayedChannel = '';
  let url = '';
  const displayed_dict = {
    0: {
      channelTitle: 'Amazon',
      url: 'https://www.oxcim.net/amazon%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A%E6%96%B9%E6%B3%95'
    },
    1: {
      channelTitle: 'Amazon',
      url: 'https://www.oxcim.net/amazon%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A%E6%96%B9%E6%B3%95'
    },
    2: {
      channelTitle: '楽天市場',
      url: 'https://www.oxcim.net/%E6%A5%BD%E5%A4%A9%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A'
    },
    3: {
      channelTitle: 'Yahoo!JAPAN',
      url: 'https://www.oxcim.net/%E3%83%A4%E3%83%95%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%83%E3%83%94%E3%83%B3%E3%82%B0%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A'
    }
  };
  displayedChannel = displayed_dict[channel].channelTitle;
  url = displayed_dict[channel].url;

  return (
    <>
      {userInfo.accountStatus == 0 && <ConnectionDialog />}
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '40px'
        }}
      >
        <Container maxWidth={false} sx={{ width: 'auto', p: 0, pt: '20px' }}>
          <Box
            position="sticky"
            top="38px"
            bgcolor="#FFF8E5"
            zIndex={3}
            pt="2px"
            height="auto"
          >
            <Box display="flex">
              <Typography fontSize="16px" sx={{ py: '4px' }}>
                マーケットビュー＞
              </Typography>
              <Typography fontSize="16px" fontWeight="bold" sx={{ py: '4px' }}>
                {pageTitle}
              </Typography>
            </Box>
            <Box
              my={2}
              py={2}
              sx={{
                display: 'flex',
                borderTop: 1,
                borderBottom: 1,
                borderColor: '#FFFFFF'
              }}
            >
              <Box mr={3}>
                {/* <Typography fontSize="14px">モール</Typography> */}
                <NewButtongroup amazonOnly={true} page="category" />
              </Box>
            </Box>
          </Box>
          <Box
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 2 }}
          >
            <DialogTitle
              id="alert-dialog-title"
              fontSize="30px"
              textAlign="center"
            >
              {displayedChannel}のマーケットビューについて
            </DialogTitle>
            <DialogContent sx={{ px: 10 }}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  whiteSpace: 'pre-line',
                  color: '#575757',
                  textAlign: 'center',
                  fontSize: '20px'
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="end">
                  <Typography fontSize="20px">
                    マーケットビューの閲覧には
                  </Typography>
                  <Typography fontWeight="bold" fontSize="24px">
                    有料プランへのお申込みが必要です。
                  </Typography>
                </Box>
                <br />
                有料プランについて、詳しくは
                <Button
                  sx={{
                    p: 0,
                    m: 0,
                    fontSize: '20px',
                    minWidth: '55px',
                    textDecoration: 'underline'
                  }}
                  onClick={() => navigate('/app/sys/plan')}
                >
                  こちら
                </Button>
                をご確認ください。
                <br />
              </DialogContentText>
            </DialogContent>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default PremiumStatusDialog;
