/* eslint-disable*/
import * as React from 'react';
import {
  useEffect,
  useState,
  memo,
  useCallback,
  useRef,
  useContext
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subDays,
  startOfWeek,
  endOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  subWeeks
} from 'date-fns';
import { unwrapResult } from '@reduxjs/toolkit';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@material-ui/core/ToggleButtonGroup';
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress
} from '@material-ui/core'; // eslint-disable-line
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { DateRangePicker } from 'rsuite';
import { fetchProcessKPIPost } from 'feactures/monitoring/processKPISlice';
import { fetchViewsPost } from 'feactures/monitoring/viewsSlice';
import { fetchCVRPost } from 'feactures/monitoring/CVRSlice';
import { fetchUnitPricePost } from 'feactures/monitoring/unitPriceSlice';
import { fetchAsyncAsin, resetAsin } from 'feactures/monitoring/asinSlice';
import { fetchExport, exportStatus } from 'feactures/monitoring/exportSlice';
import {
  changeAsinTerm,
  selectAsinAlignment,
  selectAsinStartDate,
  selectAsinEndDate,
  resetAsinTerm
} from 'feactures/monitoring/asinDateSlice';
import { userinfo } from 'feactures/user/userSlice';
import { searchAsin, searchedAsin } from 'feactures/monitoring/asinSlice';
import { fetchAsyncKeywordsDetail } from 'feactures/monitoring/keywordsSlice';
import { fetchAsyncTrackingWords } from 'feactures/monitoring/trackingSlice';
import { fetchAsyncAdvertiseData } from 'feactures/monitoring/advertiseSlice';
import { fetchMonitorPost } from 'feactures/monitoring/monitoringSlice';
import { fetchGroups } from 'feactures/monitoring/groupSlice';
import {
  currentTerm,
  startDate,
  endDate,
  changeDate,
  resetDate,
  changePage,
  currentPage
} from 'feactures/date/dateSlice';
import {
  switchBenchmark,
  currentchannel,
  benchmark
} from 'feactures/channel/channelSlice';
import { groupList } from 'feactures/monitoring/groupSlice';
import { setError } from 'feactures/errorSlice';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
// import default style
import 'rsuite/dist/rsuite.min.css';
import './DatePickerStyle.css';
import { ReloadLayoutContext } from 'components/templates/dashboard/DashboardLayout';
import { ContactSupportOutlined } from '@material-ui/icons';
import { fetchAsyncMonitorAsin } from 'feactures/monitoring/monitorAsinSlice';
import { fetchOverview } from 'feactures/overview/overviewSlice';

moment.locale('ja');

//今日
const today = moment().format('YYYY-MM-DD');
//昨日
const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
//7日前
const before7day = moment().add(-7, 'days').format('YYYY-MM-DD');
//年始年末
const newyear = moment().startOf('year').format('YYYY-MM-DD');
const endofyear = moment().endOf('year').format('YYYY-MM-DD');
//前年初
const lastnewyear = moment()
  .add(-1, 'year')
  .startOf('year')
  .format('YYYY-MM-DD');
//前年末
const lastendofyear = moment()
  .add(-1, 'year')
  .endOf('year')
  .format('YYYY-MM-DD');
//月曜～金曜
const monday = moment().day(1).format('YYYY-MM-DD');
const sunday = moment().day(7).format('YYYY-MM-DD');
const lastmonday = moment().subtract(7, 'days').day(1).format('YYYY-MM-DD');
const lastsunday = moment().subtract(7, 'days').day(7).format('YYYY-MM-DD');
//当月初
const monthFirstday = moment()
  .add('months')
  .startOf('month')
  .format('YYYY-MM-DD');
//当月末
const monthLastday = moment().add('months').endOf('month').format('YYYY-MM-DD');
//先月
// const lastmonthFirstday = moment()
//   .add(-1, 'month')
//   .startOf('month')
//   .format('YYYY-MM-DD');
const startDay = startOfMonth(today); //今月1日を取得
const lastMonthStartDay = subMonths(startDay, +1);
// 前月末
// const lastmonthLastday = moment()
//   .add(-1, 'month')
//   .endOf('month')
//   .format('YYYY-MM-DD');
const lastMonthEndDay = subDays(startDay, +1);
//昨日から30日前
const thirtyDaysAgo = moment(yesterday)
  .subtract(30, 'days')
  .format('YYYY-MM-DD');

const year = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月'
];

const predefinedBottomRanges = [
  {
    label: '今日',
    value: [new Date(), new Date()]
  },
  {
    label: '今週',
    value: [startOfWeek(new Date()), endOfWeek(new Date())]
  },
  {
    label: '先週',
    // value: [subDays(new Date(), 6), new Date()]
    value: [
      startOfWeek(subWeeks(new Date(), 1)),
      endOfWeek(subWeeks(new Date(), 1))
    ]
  },
  {
    label: '今月',
    value: [startOfMonth(new Date()), endOfMonth(new Date())]
  },
  {
    label: '先月',
    value: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1))
    ]
  },
  {
    label: '今年',
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31)
    ]
  }
];

const DatePicker = memo(
  ({ setTerm, page, asinGraphTitle, pageNum, titleJa, graphTitle }) => {
    console.log('page', page);
    const { reloadStatus, setReloadStatus } = useContext(ReloadLayoutContext);
    const dispatch = useDispatch();
    const dateTerm = useSelector(currentTerm);
    const dateStart = useSelector(startDate);
    const dateEnd = useSelector(endDate);
    const datePage = useSelector(currentPage);
    const groupIdList = useSelector(groupList);
    const bench = useSelector(benchmark);

    const [alignment, setAlignment] = useState(dateTerm);
    const asinAlignment = useSelector(selectAsinAlignment);
    const asinSdate = useSelector(selectAsinStartDate);
    const asinEdate = useSelector(selectAsinEndDate);
    const searchAsinCode = useSelector(searchedAsin);
    const exportLoading = useSelector(exportStatus);
    const userInfo = useSelector(userinfo);

    var customS = new Date(dateStart); //DatePickerでDate型を使用
    var customE = new Date(dateEnd);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMenuOpen2 = Boolean(anchorEl2);
    const isMenuOpen3 = Boolean(anchorEl3);

    const [open, setOpen] = useState(false);
    const channel = useSelector(currentchannel);

    useEffect(() => {
      if (reloadStatus == true) {
        // console.log('リロードステータスはtrueですdispatch処理を発火します');
        //dispatch処理
        dispatchHandle(page);
        //リロードステータスを元に戻す
        setReloadStatus(false);
      }
    }, [reloadStatus]);

    // asinページでページ番号が変更された時
    useEffect(() => {
      if (page == 'asin') {
        dispatch(resetAsin());
        setAlignment(asinAlignment);
        // console.log('fetchasin asinページでページ番号が変更された時');
        dispatch(
          fetchAsyncAsin({
            startDate: asinSdate,
            endDate: asinEdate,
            term: asinAlignment,
            asinTitle: asinGraphTitle,
            pageNum: pageNum
          })
        );
      }
    }, [pageNum]);

    let label = [];
    const list_all_date = (params_start, params_end) => {
      label = [];
      var start = moment(params_start),
        end = moment(params_end);
      while (start.diff(end) <= 0) {
        label.push(start.format('M/D'));
        start.add(1, 'days');
      }
    };

    const list_all_week = (params_start, params_end) => {
      label = [];
      var start = moment(params_start),
        end = moment(params_end);
      while (start.diff(end) <= 0) {
        label.push(start.format('M/D (dd)'));
        start.add(1, 'days');
      }
    };

    const dispatchTable = {
      overview: [fetchOverview()],
      monitoring: [fetchGroups(), fetchMonitorPost()],
      // kpi: [fetchProcessKPIPost()],
      sales: [fetchAsyncMonitorAsin({ pageNum: 1 })],
      views: [fetchProcessKPIPost(), fetchViewsPost()],
      cvr: [fetchProcessKPIPost(), fetchCVRPost()],
      customerUnitPrice: [fetchProcessKPIPost(), fetchUnitPricePost()],
      keywordsDetail: [fetchAsyncKeywordsDetail()],
      asin: [
        fetchAsyncAsin({
          startDate: asinSdate,
          endDate: asinEdate,
          term: asinAlignment,
          asinTitle: asinGraphTitle,
          pageNum: 1
        })
      ],
      tracking: [fetchAsyncTrackingWords({ graphTitle })],
      advertise: [fetchAsyncAdvertiseData({ graphTitle })]
    };

    const dispatchHandle = useCallback((currentPage) => {
      // dispatch(resetDate()); //dateのリセットを完了してから以下を実行
      // console.log('datepicker のdispatchTable 発火');
      return [...dispatchTable[currentPage]].map((r, index) => dispatch(r)); //dispatchTableのreducerの数だけdispatchさせる
      // });
    }, []);

    // ページが変わった時
    useEffect(() => {
      dispatch(changePage({ page }));
      dispatch(switchBenchmark('1')); //比較を初期化

      // if (page === 'asin' || page === 'tracking') {
      //asinページまたは掲載ページのときは日付をそのままにしてfetch
      // dispatchHandle(page);
      // } else {
      //asinページまたは掲載順位ページじゃないときは日付をリセット
      // dispatch(resetAsinTerm());
      dispatchHandle(page);

      //今選択されている日付で横軸をセット
      if (alignment == 'year') {
        setTerm(year);
      } else {
        list_all_date(dateStart, dateEnd);
        setTerm(label);
      }
      // }
    }, [page]);

    // 使っていない？
    // 日付が変更された時(pageは変わらない)
    const selectItem = async (start, end, e) => {
      dispatch(changeDate({ start, end, term: e.target.id }));
      handleMenuClose();
      dispatch(setError({ state: null, data: null }));
      // asinDate情報を保存
      dispatch(changeAsinTerm({ alignment: e.target.id, start, end }));

      if (e.target.id === 'week') {
        list_all_week(start, end);
        setTerm(label);
      } else if (e.target.id === 'month' || e.target.id == 'custom') {
        list_all_date(start, end);
        setTerm(label);
      } else {
        setTerm(year);
      }
      dispatchHandle(page);

      // if (page === 'monitoring') {
      //   console.log('select 時のmonitorSliceへ');
      //   dispatch(fetchMonitorPost());
      // }

      // else if (searchAsinCode) {
      //   dispatch(searchAsin({ asin: searchAsinCode, asinTitle: asinGraphTitle }));
      // } else if (page === 'keywordsDetail') {
      //   dispatch(fetchAsyncKeywordsDetail());
      // }
      // //開いている画面で接続先を変更する。 （もっと省略して書けそう）
      // // asinページの時は、今出しているasin情報をリセットして再度リクエスト
      // else if (page === 'asin') {
      //   dispatch(resetAsin());
      //   dispatch(
      //     fetchAsyncAsin({
      //       startDate: start,
      //       endDate: end,
      //       term: e.target.id,
      //       asinTitle: asinGraphTitle,
      //       pageNum: pageNum
      //     })
      //   );
      // } else if (page === 'advertise') {
      //   dispatch(
      //     fetchAsyncAdvertiseData({
      //       graphTitle: graphTitle
      //     })
      //   );
      // } else if (page === 'tracking') {
      //   dispatch(
      //     fetchAsyncTrackingWords({
      //       graphTitle: graphTitle
      //     })
      //   );
      // } else {
      //asinページ keページ以外は、どの画面でもKPIの数値は必要なので、まずprocessKPIにPOST
      // dispatch(fetchProcessKPIPost());
      // dispatch(fetchMonitorPost());

      // if (page === 'views') {
      //   dispatch(fetchViewsPost());
      // } else if (page === 'customerUnitPrice') {
      //   dispatch(fetchUnitPricePost());
      // } else if (page === 'cvr') {
      //   dispatch(fetchCVRPost());
      // }
      // }
    };

    const handleAlignment = (event, newAlignment) => {
      if (newAlignment !== null) {
        setAlignment(newAlignment);
      }
    };

    const handleProfileMenuOpen = (event) => {
      if (event.currentTarget.value === 'week') {
        setAnchorEl(event.currentTarget);
      } else if (event.currentTarget.value === 'month') {
        setAnchorEl2(event.currentTarget);
      } else {
        setAnchorEl3(event.currentTarget);
      }
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
      setAnchorEl2(null);
      setAnchorEl3(null);
    };

    // const renderMenu = (
    //   <>
    //     <Menu
    //       id="week"
    //       anchorEl={anchorEl}
    //       anchorOrigin={{
    //         vertical: 'top',
    //         horizontal: 'right'
    //       }}
    //       keepMounted
    //       transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'right'
    //       }}
    //       open={isMenuOpen}
    //       onClose={handleMenuClose}
    //     >
    //       <MenuItem
    //         id="week"
    //         onClick={(e) => selectItem(before7day, yesterday, e)}
    //       >
    //         過去1週間
    //       </MenuItem>
    //       <MenuItem id="week" onClick={(e) => selectItem(monday, sunday, e)}>
    //         今週
    //       </MenuItem>
    //       <MenuItem
    //         id="week"
    //         onClick={(e) => selectItem(lastmonday, lastsunday, e)}
    //       >
    //         先週
    //       </MenuItem>
    //     </Menu>
    //     <Menu
    //       anchorEl={anchorEl2}
    //       anchorOrigin={{
    //         vertical: 'top',
    //         horizontal: 'right'
    //       }}
    //       // id={weekmenuId}
    //       keepMounted
    //       transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'right'
    //       }}
    //       open={isMenuOpen2}
    //       onClose={handleMenuClose}
    //     >
    //       <MenuItem
    //         id="month"
    //         onClick={(e) => selectItem(thirtyDaysAgo, yesterday, e)}
    //       >
    //         過去30日間
    //       </MenuItem>
    //       <MenuItem
    //         id="month"
    //         onClick={(e) => selectItem(monthFirstday, monthLastday, e)}
    //       >
    //         今月
    //       </MenuItem>
    //       <MenuItem
    //         id="month"
    //         onClick={(e) => selectItem(lastmonthFirstday, lastmonthLastday, e)}
    //       >
    //         先月
    //       </MenuItem>
    //     </Menu>
    //     <Menu
    //       anchorEl={anchorEl3}
    //       anchorOrigin={{
    //         vertical: 'top',
    //         horizontal: 'right'
    //       }}
    //       // id={weekmenuId}
    //       keepMounted
    //       transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'right'
    //       }}
    //       open={isMenuOpen3}
    //       onClose={handleMenuClose}
    //     >
    //       <MenuItem
    //         id="year"
    //         onClick={(e) => selectItem(newyear, endofyear, e)}
    //       >
    //         今年
    //       </MenuItem>
    //       <MenuItem
    //         id="year"
    //         onClick={(e) => selectItem(lastnewyear, lastendofyear, e)}
    //       >
    //         去年
    //       </MenuItem>
    //     </Menu>
    //   </>
    // );

    return (
      <>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {/* <ThemeProvider theme={theme}>
            <ToggleButtonGroup
              color="primary"
              sx={{
                width: '150px',
                marginBottom: 0.5
              }}
              value={alignment}
              exclusive
              onChange={handleAlignment}
              alignself="stretch"
            >
              <ToggleButton
                onClick={handleProfileMenuOpen}
                sx={{ width: '50px', height: '40px' }}
                value="week"
              >
                週
              </ToggleButton>
              <ToggleButton
                onClick={handleProfileMenuOpen}
                sx={{ width: '50px', height: '40px' }}
                value="month"
              >
                月
              </ToggleButton>
              <ToggleButton
                sx={{ width: '50px', height: '40px' }}
                value="year"
                onClick={handleProfileMenuOpen}
              >
                年
              </ToggleButton>
            </ToggleButtonGroup>
          </ThemeProvider> */}
          <DateRangePicker
            ranges={predefinedBottomRanges}
            // sx={{ width: '250px' }}
            // style={{ width: 300 }}
            showOneCalendar
            onChange={(value, e) => {
              // ↓カスタムか月かの判定↓
              const startDate = new Date(value[0]);
              const endDate = new Date(value[1]);
              // console.log(startDate.setHours(0, 0, 0, 0));
              // console.log(endDate.setHours(23, 59, 59, 999));

              const startMonthFristDate = new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                1
              );
              const endMonthLastDate = new Date(
                endDate.getFullYear(),
                endDate.getMonth() + 1,
                0
              );

              let isOneMonth;
              if (
                startDate.setHours(0, 0, 0, 0) ===
                  startMonthFristDate.setHours(0, 0, 0, 0) &&
                endDate.setHours(23, 59, 59, 999) ===
                  endMonthLastDate.setHours(23, 59, 59, 999)
              ) {
                isOneMonth = true;
              } else {
                isOneMonth = false;
              }
              // ↑カスタムか月かの判定↑

              if (e.target.textContent == '今年') {
                setAlignment('year');
                dispatch(
                  changeDate({
                    start: moment(value[0]).format('YYYY-MM-DD'),
                    end: moment(value[1]).format('YYYY-MM-DD'),
                    term: 'year'
                  })
                );
              } else if (
                e.target.textContent == '先月' ||
                e.target.textContent == '今月' ||
                isOneMonth
              ) {
                setAlignment('month');
                dispatch(
                  changeDate({
                    start: moment(value[0]).format('YYYY-MM-DD'),
                    end: moment(value[1]).format('YYYY-MM-DD'),
                    term: 'month'
                  })
                );
                const start = moment(value[0]).format('YYYY-MM-DD');
                const end = moment(value[1]).format('YYYY-MM-DD');
                list_all_date(start, end);
                setTerm(label);
              } else if (
                e.target.textContent == '今週' ||
                e.target.textContent == '先週'
              ) {
                setAlignment('week');
                dispatch(
                  changeDate({
                    start: moment(value[0]).format('YYYY-MM-DD'),
                    end: moment(value[1]).format('YYYY-MM-DD'),
                    term: 'week'
                  })
                );
                const start = moment(value[0]).format('YYYY-MM-DD');
                const end = moment(value[1]).format('YYYY-MM-DD');
                list_all_date(start, end);
                setTerm(label);
              } else if (e.target.textContent == '今日') {
                setAlignment('custom');
                dispatch(
                  changeDate({
                    start: moment(value[0]).format('YYYY-MM-DD'),
                    end: moment(value[1]).format('YYYY-MM-DD'),
                    term: 'custom'
                  })
                );
                const start = moment(value[0]).format('YYYY-MM-DD');
                const end = moment(value[1]).format('YYYY-MM-DD');
                list_all_date(start, end);
                setTerm(label);
              } else {
                // 日にちを手動で入力した時
                // OKボタンを押さないと以下の処理に入らない
                // console.log('onChangeきた');
                setAlignment('custom');
                dispatch(
                  changeDate({
                    start: moment(value[0]).format('YYYY-MM-DD'),
                    end: moment(value[1]).format('YYYY-MM-DD'),
                    term: 'custom'
                  })
                );
                const start = moment(value[0]).format('YYYY-MM-DD');
                const end = moment(value[1]).format('YYYY-MM-DD');
                list_all_date(start, end);
                setTerm(label);
              }
            }}
            value={[customS, customE]}
            format="yyyy年MM月dd日"
            placement="bottomEnd"
            placeholder="日付を選択してください"
            locale={{
              year: '年',
              month: '月',
              sunday: '日',
              monday: '月',
              tuesday: '火',
              wednesday: '水',
              thursday: '木',
              friday: '金',
              saturday: '土',
              ok: '選択する'
              // formattedMonthPattern: 'yyyy年 MM月',
              // formattedDayPattern: 'yyyy年 MM月'
            }}
            onOk={(value, e) => {
              window.scrollTo(0, 0);
              if (bench != 0) {
                dispatch(setError({ state: null, data: null }));
                const start = moment(value[0]).format('YYYY-MM-DD');
                const end = moment(value[1]).format('YYYY-MM-DD');
                // console.log('OKきた');
                // console.log(label);
                // console.log(alignment);
                if (alignment == 'year' && label.length === 0) {
                  setTerm(year);
                  setAlignment('year');
                } else if (alignment == 'month') {
                  setAlignment('month');
                  // list_all_date(start, end);
                  // setTerm(label);
                } else if (alignment == 'week') {
                  setAlignment('week');
                  // list_all_date(start, end);
                  // setTerm(label);
                } else {
                  console.log('ここだよ');
                  setAlignment('custom');
                  // list_all_date(start, end);
                  // setTerm(label);
                }
                dispatch(changeAsinTerm({ alignment: alignment, start, end }));

                if (searchAsinCode) {
                  dispatch(
                    searchAsin({
                      asin: searchAsinCode,
                      asinTitle: asinGraphTitle
                    })
                  );
                } else if (page === 'keywordsDetail') {
                  dispatch(fetchAsyncKeywordsDetail());
                } else if (page === 'monitoring') {
                  dispatch(fetchMonitorPost());
                } else if (page === 'sales') {
                  dispatch(fetchAsyncMonitorAsin({ pageNum: 1 }));
                } else if (page === 'overview') {
                  dispatch(fetchOverview());
                } else {
                  //custom時も全ページでProcessKPIはしないといけないのでまずdispatch
                  dispatch(fetchProcessKPIPost());
                  if (page === 'views') {
                    dispatch(fetchViewsPost());
                  } else if (page === 'customerUnitPrice') {
                    dispatch(fetchUnitPricePost());
                  } else if (page === 'cvr') {
                    dispatch(fetchCVRPost());
                  } else if (page === 'asin') {
                    dispatch(
                      fetchAsyncAsin({
                        startDate: start,
                        endDate: end,
                        term: 'custom',
                        asinTitle: asinGraphTitle,
                        pageNum: pageNum
                      })
                    );
                  } else if (page === 'advertise') {
                    dispatch(
                      fetchAsyncAdvertiseData({
                        graphTitle: graphTitle
                      })
                    );
                  } else if (page === 'tracking') {
                    dispatch(
                      fetchAsyncTrackingWords({
                        graphTitle: graphTitle
                      })
                    );
                  }
                }
              }
            }}
          />
        </Box>
        {/* {renderMenu} */}
      </>
    );
  }
);

export default DatePicker;
