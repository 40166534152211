/* eslint-disable*/
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { userinfo } from 'feactures/user/userSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Billing = () => {
  const navigate = useNavigate();
  const userInfo = useSelector(userinfo);
  const userPlan = userInfo.plan;
  const userPayment = userInfo.payment;
  const userMailList = userInfo.userList.map((user) => user.userId);
  const filteredMailList = userMailList.filter((address) => {
    return !address.includes('opt.ne.jp');
  });

  const date = new Date();
  const targetDate = new Date(date.getFullYear(), date.getMonth(), 25);
  const thisMonth = date.getMonth() + 1;
  const tableLen = userPayment.amazon.length;

  const modoru = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff8e5',
        height: 'auto',
        minHeight: '100vh',
        width: '100%'
        // display: 'flex',
        // alignItems: 'center'
      }}
    >
      <Container sx={{ width: 'auto', py: 3 }}>
        <Typography fontSize="16px" fontWeight="bold">
          ご契約状況
        </Typography>
        <Box mb={3} mt={1}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="a dense table" size="small">
              <TableHead>
                {/* モール */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      bgcolor: '#F1F5F6',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      width: '200px'
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      bgcolor: '#F1F5F6',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      width: '200px'
                    }}
                  >
                    Amazon
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      bgcolor: '#F1F5F6',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      width: '200px'
                    }}
                  >
                    楽天
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      bgcolor: '#F1F5F6',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      width: '200px'
                    }}
                  >
                    Yahoo!
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* プラン */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    プラン
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderLeft: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.amazon.plan}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.rakuten.plan}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.yahoo.plan}
                  </TableCell>
                </TableRow>
                {/* 初期費用 */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    初期費用(初月のみ)
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    sx={{
                      // borderRight: 1,
                      borderLeft: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.amazon.initial_fee_campaign == 1 ||
                    userPlan.rakuten.initial_fee_campaign == 1 ||
                    userPlan.yahoo.initial_fee_campaign == 1
                      ? '¥0(税込み)'
                      : (userPlan.amazon.plan == 'ご利用なし' ||
                          userPlan.amazon.plan == 'トライアルプラン') &&
                        (userPlan.rakuten.plan == 'ご利用なし' ||
                          userPlan.rakuten.plan == 'トライアルプラン') &&
                        (userPlan.yahoo.plan == 'ご利用なし' ||
                          userPlan.yahoo.plan == 'トライアルプラン')
                      ? ''
                      : '¥55,000(税込み)'}
                  </TableCell>
                </TableRow>
                {/* 月額利用費 */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    月額利用費
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderLeft: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.amazon.plan == 'ご利用なし'
                      ? ''
                      : `¥${userPlan.amazon.price.toLocaleString()}(税込み)`}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.rakuten.plan == 'ご利用なし'
                      ? ''
                      : `¥${userPlan.rakuten.price.toLocaleString()}(税込み)`}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.yahoo.plan == 'ご利用なし'
                      ? ''
                      : `¥${userPlan.yahoo.price.toLocaleString()}(税込み)`}
                  </TableCell>
                </TableRow>
                {/* ご契約期間 */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    ご契約期間
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderLeft: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.amazon.plan == 'ご利用なし' ||
                    userPlan.amazon.status == 99
                      ? ''
                      : `${userPlan.amazon.start_date
                          .replace('-', '/')
                          .replace('-', '/')}～${userPlan.amazon.end_date
                          .replace('-', '/')
                          .replace('-', '/')}`}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.rakuten.plan == 'ご利用なし' ||
                    userPlan.rakuten.status == 99
                      ? ''
                      : `${userPlan.rakuten.start_date
                          .replace('-', '/')
                          .replace('-', '/')}～${userPlan.rakuten.end_date
                          .replace('-', '/')
                          .replace('-', '/')}`}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.yahoo.plan == 'ご利用なし' ||
                    userPlan.yahoo.status == 99
                      ? ''
                      : `${userPlan.yahoo.start_date
                          .replace('-', '/')
                          .replace('-', '/')}～${userPlan.yahoo.end_date
                          .replace('-', '/')
                          .replace('-', '/')}`}
                  </TableCell>
                </TableRow>
                {/* 適用中キャンペーン */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    適用中キャンペーン
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderLeft: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    <div>
                      {userPlan.amazon.initial_fee_campaign == 1 &&
                        '初期費用無料キャンペーン'}
                    </div>
                    <div>
                      {userPlan.amazon.first_month_free_campaign == 1 &&
                        '初月無料キャンペーン'}
                    </div>
                    <div>
                      {userPlan.amazon.mid_month_discount_campaign == 1 &&
                        '月中開始割引キャンペーン'}
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    <div>
                      {userPlan.rakuten.initial_fee_campaign == 1 &&
                        '初期費用無料キャンペーン'}
                    </div>
                    <div>
                      {userPlan.rakuten.first_month_free_campaign == 1 &&
                        '初月無料キャンペーン'}
                    </div>
                    <div>
                      {userPlan.rakuten.mid_month_discount_campaign == 1 &&
                        '月中開始割引キャンペーン'}
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    <div>
                      {userPlan.yahoo.initial_fee_campaign == 1 &&
                        '初期費用無料キャンペーン'}
                    </div>
                    <div>
                      {userPlan.yahoo.first_month_free_campaign == 1 &&
                        '初月無料キャンペーン'}
                    </div>
                    <div>
                      {userPlan.yahoo.mid_month_discount_campaign == 1 &&
                        '月中開始割引キャンペーン'}
                    </div>
                  </TableCell>
                </TableRow>
                {/* 更新方法 */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    更新方法
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderLeft: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.amazon.plan == 'ご利用なし' ||
                    userPlan.amazon.plan == 'トライアルプラン'
                      ? ''
                      : '自動更新'}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,

                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.rakuten.plan == 'ご利用なし' ||
                    userPlan.rakuten.plan == 'トライアルプラン'
                      ? ''
                      : '自動更新'}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.yahoo.plan == 'ご利用なし' ||
                    userPlan.yahoo.plan == 'トライアルプラン'
                      ? ''
                      : '自動更新'}
                  </TableCell>
                </TableRow>
                {/* 解約申請期限 */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    解約申請期限
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderLeft: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.amazon.plan == 'ご利用なし' ||
                    userPlan.amazon.plan == 'トライアルプラン'
                      ? ''
                      : '契約期間満了月の前月末まで'}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.rakuten.plan == 'ご利用なし' ||
                    userPlan.rakuten.plan == 'トライアルプラン'
                      ? ''
                      : '契約期間満了月の前月末まで'}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.yahoo.plan == 'ご利用なし' ||
                    userPlan.yahoo.plan == 'トライアルプラン'
                      ? ''
                      : '契約期間満了月の前月末まで'}
                  </TableCell>
                </TableRow>
                {/* 登録メールアドレス */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: 0,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    登録メールアドレス
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: 0,
                      borderRight: 1,
                      borderLeft: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.amazon.plan == 'ご利用なし'
                      ? ''
                      : filteredMailList.map((mail, index) => {
                          if (index == 0) {
                            return mail;
                          } else {
                            return <div key={index}>{mail}</div>;
                          }
                        })}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: 0,
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.rakuten.plan == 'ご利用なし'
                      ? ''
                      : filteredMailList.map((mail, index) => {
                          if (index == 0) {
                            return mail;
                          } else {
                            return <div key={index}>{mail}</div>;
                          }
                        })}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottom: 0,
                      borderColor: '#E0E0E0',
                      fontSize: '14px'
                    }}
                  >
                    {userPlan.yahoo.plan == 'ご利用なし'
                      ? ''
                      : filteredMailList.map((mail, index) => {
                          if (index == 0) {
                            return mail;
                          } else {
                            return <div key={index}>{mail}</div>;
                          }
                        })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography fontSize="16px" fontWeight="bold">
          ご請求情報
        </Typography>
        <Box mb={3} mt={1}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="a dense table" size="small">
              <TableHead>
                {/* モール */}
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      bgcolor: '#F1F5F6',
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px',
                      width: '290px'
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      bgcolor: '#F1F5F6',
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px',
                      width: '290px',
                      fontWeight: 'bold'
                    }}
                  >
                    Amazon
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      bgcolor: '#F1F5F6',
                      borderRight: 1,
                      borderColor: '#E0E0E0',
                      fontSize: '14px',
                      width: '290px',
                      fontWeight: 'bold'
                    }}
                  >
                    楽天
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      bgcolor: '#F1F5F6',
                      fontSize: '14px',
                      width: '290px',
                      fontWeight: 'bold'
                    }}
                  >
                    Yahoo!
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userPayment.amazon.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {/*請求金額 */}
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: 1,
                            borderBottom: index == tableLen - 1 ? 0 : 1,
                            borderColor: '#E0E0E0',
                            bgcolor:
                              index == 0
                                ? '#D9D9D9'
                                : index == 1
                                ? '#4A86E8'
                                : '#FFFFFF',
                            fontSize: '14px',
                            color: index == 1 ? '#FFFFFF' : '#000000',
                            fontWeight: index == 1 ? 'bold' : 'normal'
                          }}
                        >
                          {item.used_month}月ご利用分請求金額
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            bgcolor:
                              index == 0
                                ? '#F3F3F3'
                                : index == 1
                                ? '#C9DAF8'
                                : '#FFFFFF',
                            borderRight: 1,
                            borderBottom: index == tableLen - 1 ? 0 : 1,
                            borderColor: '#E0E0E0',
                            fontSize: '14px'
                          }}
                        >
                          ¥{item.price.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            bgcolor:
                              index == 0
                                ? '#F3F3F3'
                                : index == 1
                                ? '#C9DAF8'
                                : '#FFFFFF',
                            borderRight: 1,
                            borderBottom: index == tableLen - 1 ? 0 : 1,
                            borderColor: '#E0E0E0',
                            fontSize: '14px'
                          }}
                        >
                          ¥{userPayment.rakuten[index].price.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            borderBottom: index == tableLen - 1 ? 0 : 1,
                            borderColor: '#E0E0E0',
                            bgcolor:
                              index == 0
                                ? '#F3F3F3'
                                : index == 1
                                ? '#C9DAF8'
                                : '#FFFFFF',
                            fontSize: '14px'
                          }}
                        >
                          ¥{userPayment.yahoo[index].price.toLocaleString()}
                        </TableCell>
                      </TableRow>
                      {/*入金期限 */}
                      {index == 1 && (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: 1,
                              borderRightColor: '#E0E0E0',
                              bgcolor: '#4A86E8',
                              color: '#FFFFFF',
                              fontWeight: 'bold',
                              fontSize: '14px'
                            }}
                          >
                            {item.used_month}月ご利用分入金期限
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              bgcolor:
                                index == 0
                                  ? '#F3F3F3'
                                  : index == 1
                                  ? '#C9DAF8'
                                  : '#FFFFFF',
                              borderRight: 1,
                              borderColor: '#E0E0E0',
                              fontSize: '14px'
                            }}
                          >
                            {item.price == 0
                              ? '-'
                              : item.payment_due
                                  .replace('-', '/')
                                  .replace('-', '/')}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              bgcolor:
                                index == 0
                                  ? '#F3F3F3'
                                  : index == 1
                                  ? '#C9DAF8'
                                  : '#FFFFFF',
                              borderRight: 1,
                              borderColor: '#E0E0E0',
                              fontSize: '14px'
                            }}
                          >
                            {userPayment.rakuten[index].price == 0
                              ? '-'
                              : userPayment.rakuten[index].payment_due
                                  .replace('-', '/')
                                  .replace('-', '/')}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              bgcolor:
                                index == 0
                                  ? '#F3F3F3'
                                  : index == 1
                                  ? '#C9DAF8'
                                  : '#FFFFFF',
                              fontSize: '14px'
                            }}
                          >
                            {userPayment.yahoo[index].price == 0
                              ? '-'
                              : userPayment.yahoo[index].payment_due
                                  .replace('-', '/')
                                  .replace('-', '/')}
                          </TableCell>
                        </TableRow>
                      )}
                      {/*入金状況 */}
                      {index <= 1 && (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: 1,
                              borderColor: '#E0E0E0',
                              bgcolor:
                                index == 0
                                  ? '#D9D9D9'
                                  : index == 1
                                  ? '#4A86E8'
                                  : '#FFFFFF',
                              fontSize: '14px',
                              color: index == 1 ? '#FFFFFF' : '#000000',
                              fontWeight: index == 1 ? 'bold' : 'normal',
                              fontSize: '14px'
                            }}
                          >
                            {item.used_month}月ご利用分入金状況
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              bgcolor:
                                index == 0
                                  ? '#F3F3F3'
                                  : index == 1
                                  ? '#C9DAF8'
                                  : '#FFFFFF',
                              borderRight: 1,
                              borderColor: '#E0E0E0',
                              color:
                                item.price != 0 && item.payment_status == 0
                                  ? 'red'
                                  : 'black',
                              fontWeight:
                                item.price != 0 && item.payment_status == 0
                                  ? 'bold'
                                  : 'nomal',
                              fontSize: '14px'
                            }}
                          >
                            {item.price == 0
                              ? '-'
                              : item.payment_status == 0
                              ? '未入金'
                              : '入金済'}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              bgcolor:
                                index == 0
                                  ? '#F3F3F3'
                                  : index == 1
                                  ? '#C9DAF8'
                                  : '#FFFFFF',
                              borderRight: 1,
                              borderColor: '#E0E0E0',
                              color:
                                userPayment.rakuten[index].price != 0 &&
                                userPayment.rakuten[index].payment_status == 0
                                  ? 'red'
                                  : 'black',
                              fontWeight:
                                userPayment.rakuten[index].price != 0 &&
                                userPayment.rakuten[index].payment_status == 0
                                  ? 'bold'
                                  : 'nomal',
                              fontSize: '14px'
                            }}
                          >
                            {userPayment.rakuten[index].price == 0
                              ? '-'
                              : userPayment.rakuten[index].payment_status == 0
                              ? '未入金'
                              : '入金済'}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              borderColor: '#E0E0E0',
                              bgcolor:
                                index == 0
                                  ? '#F3F3F3'
                                  : index == 1
                                  ? '#C9DAF8'
                                  : '#FFFFFF',
                              color:
                                userPayment.yahoo[index].price != 0 &&
                                userPayment.yahoo[index].payment_status == 0
                                  ? 'red'
                                  : 'black',
                              fontWeight:
                                userPayment.yahoo[index].price != 0 &&
                                userPayment.yahoo[index].payment_status == 0
                                  ? 'bold'
                                  : 'nomal',
                              fontSize: '14px'
                            }}
                          >
                            {userPayment.yahoo[index].price == 0
                              ? '-'
                              : userPayment.yahoo[index].payment_status == 0
                              ? '未入金'
                              : '入金済'}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography color="textSecondary" variant="body1">
          <button onClick={() => modoru()}>←戻る</button>
        </Typography>
      </Container>
    </Box>
  );
};

export default Billing;
