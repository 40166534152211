/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import * as moment from 'moment/moment';

moment.locale('ja');

//今日
const today = moment().format('YYYY-MM-DD');
//昨日
const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
//7日前
const before7day = moment().add(-7, 'days').format('YYYY-MM-DD');
//年始年末
const newyear = moment().startOf('year').format('YYYY-MM-DD');
const endofyear = moment().endOf('year').format('YYYY-MM-DD');
//前年初
const lastnewyear = moment()
  .add(-1, 'year')
  .startOf('year')
  .format('YYYY-MM-DD');
//前年末
const lastendofyear = moment()
  .add(-1, 'year')
  .endOf('year')
  .format('YYYY-MM-DD');
//月曜～金曜
const monday = moment().day(1).format('YYYY-MM-DD');
const sunday = moment().day(7).format('YYYY-MM-DD');
const lastmonday = moment().subtract(7, 'days').day(1).format('YYYY-MM-DD');
const lastsunday = moment().subtract(7, 'days').day(7).format('YYYY-MM-DD');
//当月初
const monthFirstday = moment()
  .add('months')
  .startOf('month')
  .format('YYYY-MM-DD');
//当月末
const monthLastday = moment().add('months').endOf('month').format('YYYY-MM-DD');
//先月
const lastmonthFirstday = moment()
  .add(-1, 'month')
  .startOf('month')
  .format('YYYY-MM-DD');
// 前月末
const lastmonthLastday = moment()
  .add(-1, 'month')
  .endOf('month')
  .format('YYYY-MM-DD');
//昨日から30日前
const thirtyDaysAgo = moment(yesterday)
  .subtract(30, 'days')
  .format('YYYY-MM-DD');

const year = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月'
];

let label = [];
const list_all_date = (params_start, params_end) => {
  var start = moment(params_start),
    end = moment(params_end);
  while (start.diff(end) <= 0) {
    label.push(start.format('M/D'));
    start.add(1, 'days');
  }
};
list_all_date(monthFirstday, monthLastday);

const initialState = {
  term: 'month',
  termArray: label,
  start: monthFirstday,
  end: monthLastday,
  start2: monthFirstday,
  end2: monthLastday,
  term2: 'month',
  termArray2: label,
  page: 'monitoring',
  pageFlag: true,
  tempdate: {
    term: 'month',
    termArray: label,
    start: monthFirstday,
    end: monthLastday,
    start2: monthFirstday,
    end2: monthLastday,
    term2: 'month',
    termArray2: label
  }
};

export const dateSlice = createSlice({
  name: 'date',
  initialState: initialState,
  reducers: {
    changeDate: (state, action) => {
      state.start = action.payload.start;
      state.end = action.payload.end;
      state.term = action.payload.term;
      state.termArray = action.payload.termArray;
    },
    changeDate2: (state, action) => {
      state.start2 = action.payload.start;
      state.end2 = action.payload.end;
      state.term2 = action.payload.term;
      state.termArray2 = action.payload.termArray;
    },
    changeTempDate: (state, action) => {
      state.tempdate.start = action.payload.start;
      state.tempdate.end = action.payload.end;
      state.tempdate.term = action.payload.term;
      state.tempdate.termArray = action.payload.termArray;
    },
    changeTempDate2: (state, action) => {
      state.tempdate.start2 = action.payload.start;
      state.tempdate.end2 = action.payload.end;
      state.tempdate.term2 = action.payload.term;
      state.tempdate.termArray2 = action.payload.termArray;
    },
    confirmDate: (state, action) => {
      state.start = state.tempdate.start;
      state.end = state.tempdate.end;
      state.term = state.tempdate.term;
      state.termArray = state.tempdate.termArray;
    },
    confirmDate2: (state, action) => {
      state.start2 = state.tempdate.start2;
      state.end2 = state.tempdate.end2;
      state.term2 = state.tempdate.term2;
      state.termArray2 = state.tempdate.termArray2;
    },
    resetTempDate: (state, action) => {
      state.tempdate.start = state.start;
      state.tempdate.end = state.end;
      state.tempdate.term = state.term;
      state.tempdate.termArray = state.termArray;
    },
    resetTempDate2: (state, action) => {
      state.tempdate.start2 = state.start2;
      state.tempdate.end2 = state.end2;
      state.tempdate.term2 = state.term2;
      state.tempdate.termArray2 = state.termArray2;
    },
    changeTerm: (state, action) => {
      state.term = action.payload.term;
    },
    resetDate(state) {
      return {
        ...state,
        term: 'month',
        start: thirtyDaysAgo,
        end: yesterday
      };
    },
    changePage: (state, action) => {
      // console.log('changeページ', action.payload.page);
      state.page = action.payload.page;
    },
    changePageflag: (state, action) => {
      if (
        action.payload == '/categoryboard' ||
        action.payload == '/ownproductboard' ||
        action.payload == '/otherproductboard' ||
        action.payload == '/category1/sales' ||
        action.payload == '/newcategoryboard' ||
        action.payload == '/brandanalysis' ||
        action.payload == '/brandcomparison' ||
        action.payload == '/measureanalysis'
      ) {
        // trueの時はマーケット
        state.pageFlag = false;
      } else {
        // falseの時はKPI
        state.pageFlag = true;
      }
    }
  }
});

export const {
  changeDate,
  resetDate,
  changePage,
  changePageflag,
  changeTerm,
  changeDate2,
  changeTempDate,
  changeTempDate2,
  confirmDate,
  confirmDate2,
  resetTempDate,
  resetTempDate2
} = dateSlice.actions;

export const currentTerm = (state) => state.date.term;
export const currentTerm2 = (state) => state.date.term2;
export const startDate = (state) => state.date.start;
export const endDate = (state) => state.date.end;
export const currentPage = (state) => state.date.page;
export const currentPageFlag = (state) => state.date.pageFlag;
export const startDate2 = (state) => state.date.start2;
export const endDate2 = (state) => state.date.end2;
export const termArray = (state) => state.date.termArray;
export const termArray2 = (state) => state.date.termArray2;
export const tempStart = (state) => state.date.tempdate.start;
export const tempEnd = (state) => state.date.tempdate.end;
export const tempStart2 = (state) => state.date.tempdate.start2;
export const tempEnd2 = (state) => state.date.tempdate.end2;
export const tempTerm = (state) => state.date.tempdate.term;
export const tempTerm2 = (state) => state.date.tempdate.term2;

export default dateSlice.reducer;
