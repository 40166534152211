/* eslint-disable*/
import { createTheme, responsiveFontSizes } from '@material-ui/core';

const defaultTheme = createTheme({});
const { pxToRem } = defaultTheme.typography;

let theme = createTheme({
  mixins: {
    toolbar: {
      maxHeight: 'max-content',
      paddingTop: '7px'
    }
  },
  palette: {
    action: {
      hover: '#c5c6c7'
    },
    background: {
      default: '#F7FCFF',
      paper: '#ffffff',
      header: '#6b778c'
    },
    text: {
      // primary: '#172b4d',
      primary: '#000000',
      secondary: '#6b778c',
      contrastText: '#ffffff',
      transform: 'rotate(0.05deg)'
      // secondary: '#e040fb'
      // #FCB542
    },
    neutral: {
      main: '#EB9940',
      contrastText: '#fff'
    },
    secondary: {
      main: '#bf0000',
      contrastText: '#fff'
    },
    success: {
      main: '#00C367',
      contrastText: '#fff'
    },
    info: {
      main: '#FF0027',
      contrastText: '#fff'
    },
    noselect0: {
      main: '#FFF',
      dark: '#BCDBF7',
      contrastText: '#575757'
    },
    noselect1: {
      main: '#FFF',
      dark: '#F5C89A',
      contrastText: '#575757'
    },
    noselect2: {
      main: '#FFF',
      dark: '#FFC1C1',
      contrastText: '#575757'
    },
    noselect3: {
      main: '#FFF',
      dark: '#FFC1C1',
      contrastText: '#575757'
    },
    KPINoselected: {
      main: '#575757',
      contrastText: '#F2F2F2'
    },
    KPISelected: {
      main: '#718CC6',
      contrastText: '#FFF'
    },
    groupNoselected: {
      main: '#FFF',
      contrastText: '#575757'
    },
    groupSelected: {
      main: 'rgba(113,140,198,1)',
      contrastText: '#FFF'
    },
    lightBlue: {
      main: '#C9DAF8',
      dark: '#7CA5E0',
      contrastText: '#575757'
    },
    lightGreen: {
      main: '#B6D7A8',
      dark: '#7ABC66',
      contrastText: '#575757'
    },
    newPulldown: {
      primary: '#575757',
      secondary: '#575757',
      main: '#575757',
      dark: '#575757',
      contrastText: '#575757',
      hover: '#575757'
    },
    selectBox: {
      primary: '#575757',
      secondary: '#575757',
      main: '#575757',
      dark: '#575757',
      contrastText: '#575757',
      hover: '#575757'
    },
    detailButton: {
      main: '#7189CA',
      contrastText: '#fff'
    },
    cancelButton: {
      main: '#D9D9D9',
      contrastText: '#595959'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // textTransform: 'none',
          fontWeight: 'bold'
        }
      }
    }
  },
  // palette: {
  //   action: {
  //     hover: '#c5c6c7'
  //   },
  //   background: {
  //     default: '#F7FCFF',
  //     paper: '#ffffff',
  //     header: '#6b778c'
  //   },
  //   // primary: {
  //   //   // contrastText: '#ffffff',
  //   //   main: '#172b4d',
  //   //   secondary: '#6b778c'
  //   // },
  //   text: {
  //     // primary: '#172b4d',
  //     primary: '#000000',
  //     secondary: '#6b778c',
  //     contrastText: '#ffffff'
  //     // secondary: '#e040fb'
  //   }
  // },
  shadows: [
    'none',
    '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)'
  ],
  typography: {
    color: '#6b778c',
    fontSize: 12,
    fontFamily: "'M PLUS 1p', sans-serif",
    h1: { fontSize: pxToRem(35), letterSpacing: '-0.24px' },
    h2: { fontSize: pxToRem(29), letterSpacing: '-0.24px' },
    h3: { fontSize: pxToRem(24), letterSpacing: '-0.06px' },
    h4: { fontSize: pxToRem(20), letterSpacing: '-0.06px' },
    h5: { fontSize: pxToRem(14), letterSpacing: '-0.05px' },
    h6: { fontSize: pxToRem(12), letterSpacing: '-0.05px' },
    subtitle1: { fontSize: pxToRem(18) },
    body1: { fontSize: pxToRem(16) },
    button: {
      textTransform: 'rotate(0.03deg)'
    }
  },
  components: {
    // Name of the component
    // MuiButton: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // height: '20px',
    //       // fontSize: '12px',
    //       textTransform: 'none'
    //     }
    //   }
    // },
    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        root: {
          // color: '#6b778c',
          fontSize: pxToRem(12),
          letterSpacing: '-0.05px',
          transform: 'rotate(0.03deg)'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: pxToRem(12),
          letterSpacing: '-0.05px',
          transform: 'rotate(0.03deg)'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: {
          // fontSize: pxToRem(12),
          // letterSpacing: '-0.05px'
          boxShadow: 'none',
          borderRadius: 'none'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: { fontSize: '12px' },
        transform: 'rotate(0.03deg)'
      }
    },
    MuiInputlLabel: {
      styleOverrides: {
        label: { fontSize: '12px' },
        transform: 'rotate(0.03deg)'
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
