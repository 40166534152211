/* eslint-disable  */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';
import {
  deleteProducts,
  deleteProductsList
} from 'feactures/product/deleteProductSlice';
import { fetchAsyncMyproducts } from 'feactures/product/myproductSlice';
import {
  fetchAsyncOtherproducts,
  selectOtherproducts
} from 'feactures/product/otherproductSlice';
import {
  registerProducts,
  registerStatus
} from 'feactures/product/registerProductSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  errorResMessage,
  errorResStatus,
  setError
} from 'feactures/errorSlice';
import ColorButton from 'components/atoms/button/ColorButton';
import download_icon from '../../organisms/layout/dashboard/icons/download_icon.svg';
import {
  exportStatus_productAnalysis,
  fetchExport_productAnalysis
} from 'feactures/monitoring/exportSlice';

const SimpleModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputAsin, setInputAsin] = useState('');
  const [flag, setFlag] = useState(true);
  const errorMessage = useSelector(errorResMessage); //errorメッセージ
  const channel = useSelector(currentchannel);
  const deleteList = useSelector(deleteProductsList);
  const reginsterLoading = useSelector(registerStatus);
  const [inputError, setInputError] = useState('');
  const productList = useSelector(selectOtherproducts);
  const exportStatus = useSelector(exportStatus_productAnalysis);

  useEffect(() => {
    const containsSpecialCharacters = /[\s\\￥¥]/.test(inputAsin);
    if (containsSpecialCharacters) {
      setInputError('登録できない文字が含まれます');
      setFlag(true);
    }
    // 楽天
    else if (channel == 2) {
      var asinArray = String(inputAsin).split(',');
      const pattern = /^[^:]+:[^:]+$/;
      for (const asin of asinArray) {
        if (pattern.test(asin)) {
          setFlag(false);
        } else {
          setFlag(true);
          if (inputAsin != '') {
            setInputError('「shop code:item id」の形で入力してください');
          }
        }
      }
    } else {
      if (inputAsin) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputAsin]);

  const changeAsin = (e) => {
    e.preventDefault();
    dispatch(setError(''));
    setInputError('');
    const asinList = e.target.value;
    setInputAsin(asinList);
  };

  const registerHandle = async () => {
    var asinArray = String(inputAsin).split(',');
    window.confirm(
      //eslint-disable-line
      asinArray.length + '個の商品を登録します'
    );
    await dispatch(registerProducts({ asin_code: asinArray }))
      .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のときはモーダルを閉じる
      .then(() => {
        handleClose();
        dispatch(fetchAsyncMyproducts());
        dispatch(fetchAsyncOtherproducts());
        // setInputAsin('');
      })
      .catch((err) => {
        setInputAsin(asinArray);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteProductList = () => {
    if (
      window.confirm(
        //eslint-disable-line
        '削除しますか？　※選択された商品のデータは今後蓄積されません'
      )
    ) {
      dispatch(deleteProducts());
      dispatch(fetchAsyncMyproducts());
      dispatch(fetchAsyncOtherproducts());
    } else {
      console.log('cancel');
    }
  };

  const handleClose = () => {
    dispatch(setError('')); //表示されているエラーをリセット
    setOpen(false);
    dispatch(fetchAsyncOtherproducts());
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="flex-end"
        sx={{ pt: '4px' }}
      >
        <Box sx={{ '& button': { m: 1 } }}>
          <div>
            <Button
              variant="contained"
              color="detailButton"
              onClick={handleClickOpen}
              // disabled={channel === 3}
            >
              商品登録
            </Button>

            <Button
              disabled={deleteList.length == 0}
              variant="outlined"
              color="detailButton"
              onClick={deleteProductList}
            >
              商品削除
            </Button>

            <Tooltip
              title={
                exportStatus
                  ? 'CSVダウンロード中...'
                  : (channel == 1 && productList[0].length == 0) ||
                    (channel == 2 && productList[1].length == 0) ||
                    (channel == 3 && productList[2].length == 0)
                  ? ''
                  : 'CSVダウンロード'
              }
              placement="top"
            >
              <span>
                <IconButton
                  onClick={() => {
                    dispatch(fetchExport_productAnalysis());
                  }}
                  disabled={
                    exportStatus ||
                    (channel == 1 && productList[0].length == 0) ||
                    (channel == 2 && productList[1].length == 0) ||
                    (channel == 3 && productList[2].length == 0)
                  }
                >
                  <img
                    src={download_icon}
                    alt="ダウンロード"
                    style={{ width: '20px' }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </Box>
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="700px">
        <DialogTitle>商品登録</DialogTitle>
        <DialogContent sx={{ pt: '10px !important' }}>
          {/* <SimpleField handleClose={handleClose} /> */}
          <TextField
            id="outlined-search"
            label={
              channel == 1
                ? 'ASIN'
                : channel == 2
                ? 'ショップコード:商品管理番号'
                : '商品番号'
            }
            type="search"
            value={inputAsin}
            onChange={changeAsin}
            fullWidth
          />
          {inputError != '' && (
            <Typography color="red">{inputError}</Typography>
          )}
          {errorMessage ? (
            <Typography color="red">{errorMessage}</Typography>
          ) : (
            <Typography>
              {channel === 2 && (
                <>
                  商品登録は、ショップコードと商品管理番号をコロン( :
                  )区切りで設定して下さい
                  <br />
                </>
              )}
              複数商品を登録する際は、カンマで区切ってください
              （最大で100個まで登録可能）
              <br />
              {channel === 1 && <>※入力例：B2FBDK23, BLSIF6U7, BLDIEM5</>}
              {channel === 2 && (
                <>
                  ※入力例：oxcim-shop:1234567
                  <br />
                  ▼ショップコード確認方法
                  <br />
                  該当商品の店舗ページを表示 　
                  <br />
                  ↓
                  <br />
                  URLの2階層目をご確認下さい
                  <br />
                  https://item.rakuten.co.jp/&#123;ショップコード&#125;/&#123;商品管理番号&#125;/
                </>
              )}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {errorMessage == '1000位以内に入っていない商品が含まれます' ? (
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <ColorButton disabled={flag} onClick={registerHandle}>
                {reginsterLoading ? <CircularProgress size={15} /> : '登録'}
              </ColorButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SimpleModal;
